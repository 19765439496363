<template>
  <b-modal
    id="create-qualification-record-modal"
    title="Create Qualification Record"
    lazy
    size="lg"
    no-close-on-backdrop
    ok-title="Create"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    :ok-disabled="loading"
    :cancel-disabled="loading"
    @hidden="onClose"
    @ok.prevent="onSubmit"
  >
    <b-form-group label="Qualification Method" label-for="ddl-qualification-method">
      <v-select
        id="ddl-qualification-method"
        v-model="selectedMethod"
        :options="methodOptions"
        :clearable="false"
        :disabled="loading"
        :reduce="obj => obj.id"
        label="name"
      />
    </b-form-group>

    <b-form-group label="Qualification Phase" label-for="ddl-qualification-phase">
      <v-select
        id="ddl-qualification-phase"
        v-model="selectedPhase"
        :options="phaseOptions"
        :clearable="false"
        :disabled="loading"
        :reduce="obj => obj.id"
        label="name"
      />
    </b-form-group>

    <b-form-group label="Qualification Statement" label-for="ddl-qualification-statement">
      <tip-tap-editor
        id="ddl-qualification-statement"
        v-model="statement"
        :allow-image-upload="false"
        :disabled="loading"
        placeholder="Enter a Qualification Statement..."
        min-height="10"
        max-height="10"
      />
    </b-form-group>

    <b-form-group label="Result" label-for="record-result">
      <v-select
        v-model="selectedResult"
        :options="resultOptions"
        :clearable="false"
        :disabled="loading"
        :reduce="obj => obj.id"
        label="name"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  name: 'CreateQualificationRecordModal',
  components: {
    vSelect,
    TipTapEditor,
  },
  props: {
    parentId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const methodOptions = computed(() => store.getters['constants/qualificationMethods'])
    const phaseOptions = computed(() => store.getters['constants/qualificationPhases'])
    const resultOptions = computed(() => store.getters['constants/qualificationResults'])

    const loading = ref(false)
    const selectedMethod = ref('')
    const selectedPhase = ref('')
    const selectedResult = ref('Not Determined')
    const statement = ref('')

    const onClose = () => {
      loading.value = false
      selectedMethod.value = null
      selectedPhase.value = null
      selectedResult.value = 'Not Determined'
      statement.value = ''
      context.root.$bvModal.hide('create-qualification-record-modal')
    }
    const onSubmit = () => {
      const data = {
        method: selectedMethod.value,
        phase: selectedPhase.value,
        statement: statement.value,
        record_result: selectedResult.value,
        parent_node_id: props.parentId,
      }

      loading.value = true
      store.dispatch('qualificationRecords/createQualificationRecord', data)
        .then(data => { context.emit('on-create', data) })
        .finally(() => {
          loading.value = false
          onClose()
        })
    }

    return {
      loading,
      methodOptions,
      phaseOptions,
      resultOptions,

      selectedMethod,
      selectedPhase,
      selectedResult,
      statement,

      onClose,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
